import Popover from '@/components/Popover';
import UserAvatar from '@/components/UserAvatar';
import { useAuthStore } from '@/lib/drupal/stores/auth';
import { faArrowDown, faBars, faChevronCircleDown, faChevronDown, faCoins, faEllipsis, faEllipsisV, faEllipsisVertical, faSignOut, faUser } from '@fortawesome/free-solid-svg-icons';
import { useRef, useState } from 'react';
import { Button, createComponent, Dropdown, DropdownDivider, DropdownItem, DropdownLink, Flex, IconFA, If, Slot, useNotifications } from 'react-commons';

import style from '../index.module.scss';
import LoginButton from '@/components/LoginButton';
import AuthForms from '@/components/AuthForms';
import Link from 'next/link';
import { useAuthMethods } from '@/lib/hooks/useAuthMethods';
import axios from 'axios';
import { useRouter } from 'next/router';

export default createComponent('UserStatus', { style }, function UserStatus ({ className }) {
  const elRef = useRef<HTMLDivElement | null>(null);
  const [ authState ] = useAuthStore();
  const [ formVisible, setFormVisible ] = useState<'login' | 'register' | 'resetPassword'>('login');
  const { logout } = useAuthMethods();
  const router = useRouter();
  const { notify } = useNotifications();

  const onPopoverOpen = () => {
    setFormVisible('login');
  };

  const purgeCache = async () => {
    notify('Purging cache...');
    try {
      await axios.get(
        `/api/revalidate?path=${router.asPath}`
      );
    } catch (e) {
      notify('Error revalidating cache.', 'error');
    }

    notify('Cache purged. Reloading the page...', 'success');
    window.location.reload();
  };

  return (
    <div className={className} ref={elRef}>
      {
        If(authState.user, () => (
          <Flex className='UserStatus__User' container gap0 alignCenter>
            <Flex>
              <Link href={`/member/profiles/${authState.user.displayName}`}>
                <div>
                  <UserAvatar 
                    small
                    src={authState.user.avatarUrl} 
                    displayName={authState.user.displayName}
                  />
                </div>
              </Link>
            </Flex>
            <Flex directionColumn>
              <Flex>
                <Link href={`/member/profiles/${authState.user.displayName}`}>
                  <p>{authState.user.displayName}</p>
                </Link>
              </Flex>
              <Flex>
                <Flex tag='p' alignCenter>
                  <IconFA icon={faCoins} className='--marSm1__r' />
                  {
                    If(authState.user.tokenBalance >= 0, () => (
                      <>{(authState.user.tokenBalance).toLocaleString('en-US')}</>
                    ))
                      .Else(() => (
                        <></>
                      ))
                      .EndIf()
                  }
                </Flex>
              </Flex>
            </Flex>
            <Flex>
              <Dropdown right>
                <Slot name='trigger'>
                  <Button secondary small>
                    <IconFA icon={faBars} />
                  </Button>
                </Slot>
                <Slot name='content'>
                  <DropdownLink href={`/member/profiles/${authState.user.displayName}?tab=favoriteGames`} onClick={() => true}>
                    My Favorites
                  </DropdownLink>
                  <DropdownLink href={'/account/user/edit'}>
                    Account Settings
                  </DropdownLink>
                  <DropdownLink href={'/account/user/edit/subscription'}>
                    Subscription
                  </DropdownLink>
                  <DropdownDivider />
                  <DropdownItem onClick={logout}>
                    Logout
                  </DropdownItem>
                  {
                    If(authState.user.isAdminUser, () => (
                      <>
                        <DropdownDivider />
                        <DropdownItem onClick={purgeCache}>
                          Purge Page Cache
                        </DropdownItem>
                      </>
                    )).EndIf()
                  }
                </Slot>
              </Dropdown>
            </Flex>
          </Flex>
        ))
          .Else(() => (
            <LoginButton />
          ))
          .EndIf()
      }
    </div>
  );
});